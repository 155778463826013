import React from 'react';
import { Grid } from '@chakra-ui/react';

type AppGroupProps = {
  children: React.ReactNode;
};

const AppGroup = ({ children }: AppGroupProps) => (
  <Grid
    templateColumns={{
      md: 'repeat(auto-fit, minmax(300px, 1fr))',
    }}
    gap={6}
  >
    {children}
  </Grid>
);

export default AppGroup;
