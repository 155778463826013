import React from 'react';
import { useSession } from 'next-auth/client';
import AppList from '@/src/components/apps/AppList';
import PageHeading from '@/src/shared/components/PageHeading';
import ContentContainer from '@/src/shared/components/ContentContainer';
import LogIn from '@/src/components/LogIn';
import { withApollo } from '@/src/lib/apollo';
import useUserPermissions from '@/src/utils/useUserPermissions';
import { Skeleton } from '@chakra-ui/react';
import Breadcrumb from '@/src/shared/components/Breadcrumb';

const Index = () => {
  const [session, sessionLoading] = useSession();
  const [userPermissions, userPermissionsLoading] = useUserPermissions();

  if (typeof window === 'undefined') return null;

  if (!session) return <LogIn />;

  return (
    <>
      <ContentContainer>
        <Breadcrumb items={[{ title: 'Home', href: '/' }]} />
      </ContentContainer>
      <PageHeading title="My Apps" />
      <ContentContainer>
        <Skeleton isLoaded={!sessionLoading || !userPermissionsLoading}>
          <AppList userPermissions={userPermissions} />
        </Skeleton>
      </ContentContainer>
    </>
  );
};

export default withApollo({ ssr: false })(Index);
