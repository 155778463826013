import React from 'react';
import AppGroup from '@/src/components/apps/AppGroup';
import AppCard from '@/src/components/apps/AppCard';
import ManifestIcon from '@/public/static/manifest.png';
import Permission from '@/src/shared/components/Permission';

type AppListProps = {
  userPermissions: string[];
};

const AppList = ({ userPermissions }: AppListProps) => {
  return (
    <AppGroup>
      <Permission
        required={['app.manifest:access']}
        has={userPermissions}
        otherwise={<noscript />}
      >
        <AppCard
          route="/manifest"
          title="Manifests"
          icon={ManifestIcon}
          description="Create and manage manifests for deliveries in the Matternet network."
        />
      </Permission>
      <Permission
        required={['app.type-cert-input:access']}
        has={userPermissions}
        otherwise={<noscript />}
      >
        <AppCard
          route="/tc-manual-data"
          title="TC Input"
          icon={ManifestIcon}
          description="Create and update manual entries for type cert flights."
        />
      </Permission>
      <Permission
        required={['app.air-traffic-conflict:access']}
        has={userPermissions}
        otherwise={<noscript />}
      >
        <AppCard
          route="/air-traffic-conflict"
          title="AT Conflict"
          icon={ManifestIcon}
          description="Generate simulated airtraffic conflicts."
        />
      </Permission>
      <Permission
        required={['app.user-management:access']}
        has={userPermissions}
        otherwise={<noscript />}
      >
        <AppCard
          route="/user-management"
          title="User Management"
          icon={ManifestIcon}
          description="Create, manage, and assign roles to users."
          data-testid="user-management-app-card"
        />
      </Permission>
      <Permission
        required={['app.aircraft-assignment:access']}
        has={userPermissions}
      >
        <AppCard
          route="/aircraft-assignment"
          title="Pilot Assignment"
          icon={ManifestIcon}
          description="Assign pilots to aircraft."
        />
      </Permission>

      <Permission
        required={['app.network-management:access']}
        has={userPermissions}
      >
        <AppCard
          route="/network-management"
          title="Network Management"
          icon={ManifestIcon}
          description="Manage networks."
          data-testid="network-management-app-card"
        />
      </Permission>
    </AppGroup>
  );
};

export default AppList;
