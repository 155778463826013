import React from 'react';
import { Avatar, Box, Text, Stack } from '@chakra-ui/react';
import { useRouter } from 'next/router';

interface IAppCardProps {
  /** Title of application */
  title: string;
  icon: any;
  description: string;
  /** Next.JS page route */
  route?: string;
  /** External URL, cannot be used alongside `route` */
  externalUrl?: string;
}

const AppCard = ({ title, route, externalUrl, ...props }: IAppCardProps) => {
  const router = useRouter();

  if (route && externalUrl) {
    throw Error(
      'AppCard cannot accept both a route and externalUrl! Please choose one.',
    );
  }

  const handleAppRoute = () => {
    if (route) {
      router.push(route);
    }

    if (externalUrl) {
      window.open(externalUrl, '_blank');
    }
  };

  return (
    <Box
      as="button"
      maxWidth="600px"
      onClick={handleAppRoute}
      rounded="lg"
      py="8"
      px="8"
      shadow="md"
      cursor="pointer"
      borderWidth="1px"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      borderColor="gray.200"
      _hover={{
        shadow: '2xl',
      }}
      _focus={{
        shadow: '2xl',
      }}
      {...props}
    >
      <Stack spacing="6" alignItems="center">
        <Avatar name={title} size="xl" bg="blue.500" color="white" />
        <Text
          textAlign="center"
          fontSize="xl"
          fontWeight="medium"
          // color="blue.600"
        >
          {title}
        </Text>
        {/* <Text>{description}</Text> */}
      </Stack>
    </Box>
  );
};

export default AppCard;
